import React, { useEffect, useState } from "react";
import classes from "./Profile.module.css";
import shapeLeft from "../../imgs/aboutUs/light-left.png";
import profileData from "../../Pages/About-us/profileData";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export default function Profile() {
  const { t } = useTranslation();

  const { id } = useParams();
  const [selectedData, setSelectedData] = useState({});

  const getById = (id) => {
    let selectedData = profileData.filter((val) => val.id == id);
    setSelectedData(selectedData[0]);
    // console.log(selectedData);
  };

  // console.log(id);

  useEffect(() => {
    getById(id);
  }, []);

  return (
    <div className={classes.header}>
      <div className={classes.rectangle}>
        <div className={classes.rectangle1}></div>
        <div className={classes.rectangle2}></div>
        <div className={classes.rectangle3}></div>
      </div>
      <div className={classes.img}>
        {selectedData.image && (
          <img
            src={selectedData.image}
            className={classes.profileSrc}
            alt="BigCo Inc. logo"
          />
        )}
      </div>
      <div className={classes.profile}>
        <div className={classes.imgshapeLeft}>
        <div className={classes.shape}>
      <NavLink
            to="/about-us"

>
        <img src={shapeLeft} className={classes.shapeLeft} alt="" />
        <img src={shapeLeft} className={classes.shapeLeft} alt="" />
 </NavLink>
      </div>
        </div>
        {selectedData.name && (
          <h1 className={classes.Name}>{t(selectedData.name)}</h1>
        )}
        {selectedData.Postion && (
          <h1 className={classes.Postion}>{t(selectedData.Postion)}</h1>
        )}
        <div className={classes.PostionP}>
          {/* {selectedData.data1 && <li>{t(selectedData.data1)}</li>}
          {selectedData.data2 && <li>{t(selectedData.data2)}</li>}
          {selectedData.data3 && <li>{t(selectedData.data3)}</li>}
          {selectedData.data4 && <li>{t(selectedData.data4)}</li>}
          {selectedData.data5 && <li>{t(selectedData.data5)}</li>}
          {selectedData.data6 && <li>{t(selectedData.data6)}</li>} */}

           {selectedData && selectedData.favoriteMovies?.map((item,key) => (
            
            <li key={key}>{t(item)}</li>
          ))}
        </div>
      </div>
    </div>
  );
}
