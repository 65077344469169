import React, { useEffect, useState } from "react";
import classes from "./Blog.module.css";
import Blogs from "../../imgs/Blogs/Group 238.png";
import shapeLeft from "../../imgs/aboutUs/light-left.png";
import blogeData from "../../Pages/Blogs/BlogData";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ToolBar from "../ToolBar/ToolBar";
import { NavLink } from "react-router-dom";

export default function Blog() {
  const { t } = useTranslation();

  const { id } = useParams();
  const [selectedData, setSelectedData] = useState({});

  const getById = (id) => {
    let selectedData = blogeData.filter((val) => val.id == id);
    setSelectedData(selectedData[0]);
    // console.log(selectedData);
  };

  useEffect(() => {
    getById(id);
  }, []);
  return (
    <div className={classes.body}>
      <div className={classes.shape}>
      <NavLink
            to="/blogs"

>
        <img src={shapeLeft} className={classes.shapeLeft} alt="" />
        <img src={shapeLeft} className={classes.shapeLeft} alt="" />
 </NavLink>
      </div>
      <div className={classes.rectangleBG}></div>

      <div className={classes.blog}>
        {selectedData.title && <h1>{t(selectedData.title)}</h1>}
        <div className={classes.img}>
          <div className={classes.rectangle}></div>
          {selectedData.image2 && (
            <img src={selectedData.image2} className={classes.image} alt="" />
          )}
        </div>
        {selectedData.data && <p>{t(selectedData.data)}</p>}
        {selectedData.data1 && <p>{t(selectedData.data1)}</p>}
        <ul>
          {selectedData.li1 && <li>{t(selectedData.li1)}</li>}
          {selectedData.li1p1 && (
            <p className={classes.pli}>{t(selectedData.li1p1)}</p>
          )}
          {selectedData.li2 && <li>{t(selectedData.li2)}</li>}
          {selectedData.li1p2 && (
            <p className={classes.pli}>{t(selectedData.li1p2)}</p>
          )}
          {selectedData.li3 && <li>{t(selectedData.li3)}</li>}
          {selectedData.li1p3 && (
            <p className={classes.pli}>{t(selectedData.li1p3)}</p>
          )}
          {selectedData.li4 && <li>{t(selectedData.li4)}</li>}
          {selectedData.li1p4 && (
            <p className={classes.pli}>{t(selectedData.li1p4)}</p>
          )}
          {selectedData.li5 && <li>{t(selectedData.li5)}</li>}
          {selectedData.li1p5 && (
            <p className={classes.pli}>{t(selectedData.li1p5)}</p>
          )}
          {selectedData.li1p6 && (
            <p className={classes.pli}>
              <b>{t(selectedData.li1p6)}</b>
            </p>
          )}
          {selectedData.li1p7 && (
            <p className={classes.pli}>{t(selectedData.li1p7)}</p>
          )}
          {selectedData.li6 && <li>{t(selectedData.li6)}</li>}
          {selectedData.li7 && <li>{t(selectedData.li7)}</li>}
          {selectedData.li8 && <li>{t(selectedData.li8)}</li>}
          {selectedData.li9 && <li>{t(selectedData.li9)}</li>}
          {selectedData.li10 && <li>{t(selectedData.li10)}</li>}
          {selectedData.li1p11 && <li>{t(selectedData.li11)}</li>}
          {selectedData.li12 && <li>{t(selectedData.li12)}</li>}
          {selectedData.li13 && <li>{t(selectedData.li13)}</li>}
          {selectedData.li14 && <li>{t(selectedData.li14)}</li>}
        </ul>
        <ToolBar />
      </div>
    </div>
  );
}
