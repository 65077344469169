import React, { useState } from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { AiOutlineInstagram, AiOutlineWhatsApp } from "react-icons/ai";
import { RiLinkedinFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const [inputValue, setInputValue] = useState("");

  function handleClearButtonClick() {
    setInputValue("");
  }
  return (
    <div className="footer">
      <div className="footerPart1">
        <h2>{t("footer.info")}</h2>
        <div className="footerPart1Content">
          <p>{t("footer.ADDRESS")}</p>
          <p>{t("footer.ADDRESS1")}</p>
        </div>
        <div className="footerPart1Content">
          <p>{t("footer.ADDRESSPhone")}</p>
          <p>
            <span>&#x202D;{t("footer.ADDRESSPhone2")}&#x202D;</span>
          </p>
        </div>
        <div className="footerPart1Content">
          <p>{t("footer.ADDRESSEMAIL")}</p>
          <p>{t("footer.ADDRESSEMAIL1")}</p>
        </div>
      </div>
      <div className="footerPart2">
        <h2>{t("footer.Explorewebsite")}</h2>
        <div className="footerPart2Content">
          <NavLink
            className={({ isActive }) =>
              isActive ? "link-activefooter" : "linkfooter"
            }
            to="/"
          >
            <span className="nvSpan">{t("NavBar.home")}</span>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "link-activefooter" : "linkfooter"
            }
            to="/about-us"
          >
            <span className="nvSpan">{t("NavBar.About")}</span>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "link-activefooter" : "linkfooter"
            }
            to="/servies"
          >
            <span className="nvSpan">{t("NavBar.servies")}</span>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "link-activefooter" : "linkfooter"
            }
            to="/blogs"
          >
            <span className="nvSpan">{t("NavBar.Blogs")}</span>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "link-activefooter" : "linkfooter"
            }
            to="/contact_us"
          >
            <span className="nvSpan">{t("NavBar.ContactUs")}</span>
          </NavLink>
        </div>
      </div>
      <div className="footerPart3">
        <h2>{t("footer.SUBSCRIBE")}</h2>
        <div className="footerPart1Subscribe">
          <input
            value={inputValue}
            type="search"
            placeholder="عنوان البريد الإلكتروني"
            onChange={(e) => setInputValue(e.target.value)}
          ></input>
          <span onClick={handleClearButtonClick}>اشتراك</span>
        </div>
        <div className="footerPart1socialMedia">
          <NavLink
            to="https://www.facebook.com/%D8%B4%D9%88%D8%B1-%D9%84%D9%84%D8%A5%D8%B1%D8%B4%D8%A7%D8%AF-%D8%A7%D9%84%D9%85%D9%87%D9%86%D9%8A-105794589118439/?mibextid=LQQJ4d"
            target={"_blank"}
          >
            <FaFacebookF size={50} />
          </NavLink>
          <NavLink
            to="https://www.instagram.com/shorecareer/"
            target={"_blank"}
          >
            <AiOutlineInstagram size={50} />
          </NavLink>
          <NavLink to="https://twitter.com/shorecareer" target={"_blank"}>
            <BsTwitter size={50} />
          </NavLink>
          <NavLink to="https://www.tiktok.com/@shorecareer" target={"_blank"}>
            <FaTiktok size={50} />
          </NavLink>
          <NavLink
            to="https://www.linkedin.com/company/shorecareer/?original_referer="
            target={"_blank"}
          >
            <RiLinkedinFill size={50} />
          </NavLink>
          <NavLink
            to="https://api.whatsapp.com/send/?phone=966550288803&text&type=phone_number&app_absent=0"
            target={"_blank"}
          >
            <AiOutlineWhatsApp size={50} />
          </NavLink>
        </div>
      </div>
    </div>
  );
}
