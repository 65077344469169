import React from "react";
import classes from "./AboutUsHome.module.css";
import shapeLeft from "../../imgs/home/LEFT (1).png";
import shapeRight from "../../imgs/home/RIGHT (1).png";
import readMore from "../../imgs/home/readMore.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AboutUsHome() {
  const { t } = useTranslation();

  return (
    <div className={classes.Container}>
      <div className={classes.part1}>
        <hr className={classes.line}></hr>
        <h1>{t("home.about_us")}</h1>
        <hr className={classes.line}></hr>
      </div>
      <div className={classes.part2}>
        {/* <div className={classes.arrow}>
          <img
            src={shapeRight}
            className={classes.shapeLeft}
            alt="BigCo Inc. logo"
          />
        </div> */}
        <div className={classes.part2paraghraph}>
          <p className={classes.partParaghraph}>{t("home.about_us.p")}</p>
          <NavLink to={`/about-us`} className={classes.readMore}>
            <div className={classes.grpReadMore}>
              <p className={classes.part2pRead}> {t("home.readMore")}</p>
              <img
                src={readMore}
                className={classes.imgReadMore}
                alt="BigCo Inc. logo"
              />
            </div>
          </NavLink>
        </div>

        {/* <div className={classes.arrow}>
          <img
            src={shapeLeft}
            className={classes.shapeLeft}
            alt="BigCo Inc. logo"
          />
        </div> */}
      </div>
    </div>
  );
}
