// import './App.css';
import React, { useEffect } from "react";
import Navbarmenu from "./Components/NavBar/NavBar";
import Footer from "./Components/Footer/Footer";
import Home from "./Pages/Home/Home";
import About from "./Pages/About-us/About";
import Blogs from "./Pages/Blogs/Blogs";
import Servies from "./Pages/servies/Servies";
import Contact from "./Pages/Contact-us copy/Contact-Usnew.js";
import Profile from "./Components/Profile/Profile";
import Blog from "./Components/Blog/Blog";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UseLocation from "./Components/UseLocation";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  const { i18n } = useTranslation();
  const lang = localStorage.getItem("i18nextLng") || "ar";
  const scrollTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
    window.addEventListener("scroll", function () {
      AOS.refresh();
    });
  }, []);

  useEffect(() => {
    document.dir = i18n.dir();
  }, [lang]);
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Navbarmenu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/servies" element={<Servies />} />

          <Route path="/contact_us" element={<Contact />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/blog/:id" element={<Blog />} />
        </Routes>
        <Footer />
        <UseLocation/>
      </BrowserRouter>
    </>
  );
};

export default App;
