import React from 'react'
import Like from './Like'
import Share from './Share'
export default function ToolBar() {
  return (
    <div className='blog_toolBar'>
        
        {/* <Like/> */}
        <Share/>
        </div>
  )
}
