import React, { useEffect } from "react";
import classes from "./About.module.css";
// import shapeRight from "../../imgs/aboutUs/light-RIGHT.png";
// import shapeLeft from "../../imgs/aboutUs/light-left.png";
import { NavLink } from "react-router-dom";
import profileData from "./profileData";
import { useTranslation } from "react-i18next";
import OurStory from "../../Components/OurStory/OurStory"
export default function About() {
  useEffect(() => {
    document.title = "Shore || About us"
  }, [])
  const { t } = useTranslation();
  // console.log(profileData);
  return (
    <div className={classes.body}>
      <div className={classes.part1}>
        <hr className={classes.line}></hr>
        <h1>{t("about.title")}</h1>
        <hr className={classes.line}></hr>
      </div>
      <div className={classes.part2}>
        <div className={classes.part2p}>
          <p>{t("about.p")}</p>
        </div>
      </div>

      <div className={classes.part1}>
        <hr className={classes.line}></hr>
        <h1>الرؤية</h1>
        <hr className={classes.line}></hr>
      </div>
      <div className={classes.part2}>
        <div className={classes.part2p}>
          <p>نطمح إلى وصول خدماتنا الإستشارية للصدارة في المملكة العربية السعودية والعالم العربي بتمكين صنّاع المستقبل من تحقيق أهدافهم المهنية.</p>
        </div>
      </div>
      <div className={classes.part1}>
        <hr className={classes.line}></hr>
        <h1>الرسالة</h1>
        <hr className={classes.line}></hr>
      </div>
      <div className={classes.part2}>
        <div className={classes.part2p}>
          <p>صناعة مواطن منافس عالمياً.</p>
        </div>
      </div>
      <div className={classes.part1}>
        <hr className={classes.line}></hr>
        <h1>الأهداف</h1>
        <hr className={classes.line}></hr>
      </div>
      <div className={classes.part2}>
        <div className={classes.part3p}>
          <ul>
            <li>توسيع وعي المجتمع بأهمية الارشاد المهني.</li>
            <li>مساعدة الشباب على النجاح مهنياً من خلال جلسات الارشاد المهني.</li>
            <li>المساهمة في تقليص نسبة البطالة.</li>
          </ul>
        </div>
      </div>
      <OurStory />
      <div className={classes.part4}>
        <hr className={classes.line}></hr>
        <h1>{t("about.lai_te5tarShore")}</h1>
        <hr className={classes.line}></hr>
      </div>
      <div className={classes.part2}>
        <div className={classes.part2p}>
          <p>{t("about.p2")}</p>
        </div>
      </div>
      <div className={classes.part5}>
        <h1>{t("about.ahamiyet_shore")}</h1>
      </div>
      <div className={classes.part2}>
        <div className={classes.part2p}>
          <p>{t("about.p3")}</p>
        </div>
      </div>


      <div className={classes.part3}>
        <hr className={classes.linePart2} />

        <h1>{t("about.team")}</h1>
        <hr className={classes.linePart2}></hr>
      </div>

      <div className={classes.teamPart1}>
        {profileData.map((item, index) => {
          return (
            <div className={classes.team1} key={index}>
              <div className={classes.teamImg}>
                <NavLink to={`/profile/${item.id}`} className={classes.profile}>
                  <img
                    src={item.image}
                    className={classes.profile}
                    alt="BigCo Inc. logo"
                  />

                </NavLink>
              </div>
              <div className={classes.teamText}>
                <div className={classes.rectanglerGray}></div>

                <h1 className={classes.Name}>{t(item.name)}</h1>
                <h1 className={classes.Postion}>{t(item.Postion)}</h1>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
