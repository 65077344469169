import shapeRight from "../../imgs/aboutUs/saltan-tayar.png";
import shapeLeft from "../../imgs/aboutUs/rima-bouradi.png";
import profile from "../../imgs/aboutUs/mashhal 8amdi.png";

const profileData = [
  {
    id: "سلطان_الطيار",
    name: "about.team.name1",
    Postion: "about.team.Position1",
    image: shapeRight,
    favoriteMovies: [
      "about.team.p1",
      "about.team.p1-2",
      "about.team.p1-3",
      "about.team.p1-4",
      "about.team.p1-5",
    ],
  },
  {
    id: "ريم_البواردي",
    name: "about.team.name2",
    Postion: "about.team.Position2",
    image: shapeLeft,
    favoriteMovies: [
      "about.team.p2",
      "about.team.p2-2",
      "about.team.p2-3",
      "about.team.p2-4",
      "about.team.p2-5",
    ],
  },
  {
    id: "مشعل_الغامدي",
    name: "about.team.name3",
    Postion: "about.team.Position3",
    favoriteMovies: [
      "about.team.p3",
      "about.team.p3-2",
      "about.team.p3-3",
      "about.team.p3-4",
      "about.team.p3-5",
    ],

    image: profile,
    data: "about.team.p3",
  },
];

export default profileData;
