import Blogs1 from "../../imgs/Blogs/rafad laysa e5er el 3alam.jpg";
import Blogs2 from "../../imgs/Blogs/mokabala ra2i3a waleken la youjad 3amal.jpg";
import Blogs3 from "../../imgs/Blogs/dalil latasbo7 ka2ed.jpg";
import Blogs4 from "../../imgs/Blogs/e5tisas aktar taliban.jpg";
import Blogsbackground1 from "../../imgs/home/background-blog.png";

const blogeData = [
  {
    id: 1,
    image: Blogs1,
    title: "Blogs.blog1",
    data: "Blogs.blog1.p1",
    image2: Blogsbackground1,

    data1: "Blogs.blog1.titleLarge",
    li1: "Blogs.blog1.li1",
    li1p1: "Blogs.blog1.li1p1",
    li2: "Blogs.blog1.li2",
    li1p2: "Blogs.blog1.li1p2",
    li3: "Blogs.blog1.li3",
    li1p3: "Blogs.blog1.li1p3",
    li4: "Blogs.blog1.li4",
    li1p4: "Blogs.blog1.li1p4",
    li5: "Blogs.blog1.li5",
    li1p5: "Blogs.blog1.li1p5",
    li1p6: "Blogs.blog1.li1p6",
    li1p7: "Blogs.blog1.li1p7",
  },
  {
    id: 2,
    image: Blogs2,
    title: "Blogs.blog2",
    data: "Blogs.blog2.p2",
    image2: Blogsbackground1,

    data1: "Blogs.blog2.titleLarge",
    li1: "Blogs.blog2.li1",
    li1p1: "Blogs.blog2.li1p1",
    li2: "Blogs.blog2.li2",
    li1p2: "Blogs.blog2.li1p2",
    li3: "Blogs.blog2.li3",
    li1p3: "Blogs.blog2.li1p3",
    li4: "Blogs.blog2.li4",
    li1p4: "Blogs.blog2.li1p4",
    li5: "Blogs.blog2.li5",
    li1p5: "Blogs.blog2.li1p5",
    li1p6: "Blogs.blog2.li1p6",
    li1p7: "Blogs.blog2.li1p7",
  },
  {
    id: 3,
    image: Blogs3,
    title: "Blogs.blog3",
    data: "Blogs.blog3.p3",
    image2: Blogsbackground1,

    data1: "Blogs.blog3.titleLarge",
    li1: "Blogs.blog3.li1",
    li1p1: "Blogs.blog3.li1p1",
    li2: "Blogs.blog3.li2",
    li1p2: "Blogs.blog3.li1p2",
    li3: "Blogs.blog3.li3",
    li1p3: "Blogs.blog3.li1p3",
    li4: "Blogs.blog3.li4",
    li1p4: "Blogs.blog3.li1p4",
    li5: "Blogs.blog3.li5",
    li1p5: "Blogs.blog3.li1p5",
    li1p6: "Blogs.blog3.li1p6",
    // li1p7: "Blogs.blog3.li1p7",
  },
  ,
  {
    id: 4,
    image: Blogs4,
    title: "Blogs.blog4",
    data: "Blogs.blog4.p4",
    image2: Blogsbackground1,

    data1: "Blogs.blog4.titleLarge",
    li1: "Blogs.blog4.li1",
    // li1p1: "Blogs.blog4.li1p1",
    li2: "Blogs.blog4.li2",
    // li1p2: "Blogs.blog4.li1p2",
    li3: "Blogs.blog4.li3",
    // li1p3: "Blogs.blog4.li1p3",
    li4: "Blogs.blog4.li4",
    // li1p4: "Blogs.blog4.li1p4",
    li5: "Blogs.blog4.li5",
    li6: "Blogs.blog4.li6",
    li7: "Blogs.blog4.li7",
    li8: "Blogs.blog4.li8",
    li9: "Blogs.blog4.li9",
    li10: "Blogs.blog4.li10",
    li11: "Blogs.blog4.li11",
    li12: "Blogs.blog4.li12",
    li13: "Blogs.blog4.li13",
    li14: "Blogs.blog4.li14",
    // li15: "Blogs.blog4.li15",


    // li1p5: "Blogs.blog4.li1p5",
    // li1p6: "Blogs.blog4.li1p6",
    // li1p7: "Blogs.blog3.li1p7"
  },
];

export default blogeData;
