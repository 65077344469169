import React, { useState } from "react";
import { IoIosMenu } from "react-icons/io";
import ahmadLogo from "../../imgs/logoShore.png";
import "./NavBar.css";
import { NavLink } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useTranslation } from "react-i18next";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineInstagram, AiOutlineWhatsApp } from "react-icons/ai";
import { RiLinkedinFill } from "react-icons/ri";
const Navbarmenu = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let boxClass = ["main-menu menu-right menuq1"];

  return (
    <div className="header">
      <div className="TitleName">
        <NavLink to="/" style={{ textDecoration: "none" }}>
          <img src={ahmadLogo} alt="badawi-logo" />
          <p className="NavBarPart1P">للاستشارات</p>
        </NavLink>
      </div>

      <div>
        <div className="header_container">
          <div className="header_menus">
            <nav className="main-nav ">
              <IoIosMenu
                variant="primary"
                className="d-lg-none IoIosMenu"
                onClick={handleShow}
              >
                Launch
              </IoIosMenu>

              <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title responsive="lg">
                    <div className="TitleNameRS">
                      <img src={ahmadLogo} alt="badawi-logo" />
                      <p className="NavBarPart1P">للاستشارات</p>
                    </div>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body responsive="lg" style={{ padding: "0" }}>
                  <ul className="menuResposive">
                    <li className="menu-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "link-activeRespocive" : "linkRespocive"
                        }
                        to="/"
                        onClick={handleClose}
                      >
                        {" "}
                        {t("NavBar.home")}
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "link-activeRespocive" : "linkRespocive"
                        }
                        to="/about-us"
                        onClick={handleClose}
                      >
                        {t("NavBar.About")}
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "link-active" : "link"
                        }
                        to="/servies"
                        onClick={handleClose}
                      >
                        {t("NavBar.servies")}
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "link-activeRespocive" : "linkRespocive"
                        }
                        to="/blogs"
                        onClick={handleClose}
                      >
                        {t("NavBar.Blogs")}
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "link-activeRespocive" : "linkRespocive"
                        }
                        to="/contact_us"
                        onClick={handleClose}
                      >
                        {t("NavBar.ContactUs")}
                      </NavLink>
                    </li>
                  </ul>
                </Offcanvas.Body>
                <Offcanvas.Body responsive="lg">
                  <div className="sideBarFooter">
                    <p>{t("footer.ADDRESSPhone1")}</p>
                    <p>{t("footer.ADDRESSEMAIL1")}</p>
                  </div>
                </Offcanvas.Body>

                <Offcanvas.Body responsive="lg">
                  <div className="SideBarPart1socialMedia">
                    <NavLink
                      to="https://api.whatsapp.com/send/?phone=966550288803&text&type=phone_number&app_absent=0"
                      target={"_blank"}
                    >
                      <AiOutlineWhatsApp size={50} />
                    </NavLink>{" "}
                    <NavLink
                      to="https://www.facebook.com/%D8%B4%D9%88%D8%B1-%D9%84%D9%84%D8%A5%D8%B1%D8%B4%D8%A7%D8%AF-%D8%A7%D9%84%D9%85%D9%87%D9%86%D9%8A-105794589118439/?mibextid=LQQJ4d"
                      target={"_blank"}
                    >
                      <FaFacebookF size={50} />
                    </NavLink>
                    <NavLink
                      to="https://www.instagram.com/shorecareer/"
                      target={"_blank"}
                    >
                      <AiOutlineInstagram size={50} />
                    </NavLink>
                    <NavLink
                      to="https://www.linkedin.com/company/shorecareer/?original_referer="
                      target={"_blank"}
                    >
                      <RiLinkedinFill size={50} />
                    </NavLink>
                  </div>
                </Offcanvas.Body>
                <Offcanvas.Body responsive="lg"></Offcanvas.Body>
              </Offcanvas>
              <ul className={boxClass.join(" ")}>
                <li className="menu-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "link-active" : "link"
                    }
                    to="/"
                  >
                    {" "}
                    {t("NavBar.home")}
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "link-active" : "link"
                    }
                    to="/about-us"
                  >
                    {t("NavBar.About")}
                  </NavLink>
                </li>
               
                <li className="menu-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "link-active" : "link"
                    }
                    to="/servies"
                  >
                    {t("NavBar.servies")}
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "link-active" : "link"
                    }
                    to="/blogs"
                  >
                    {t("NavBar.Blogs")}
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "link-active" : "link"
                    }
                    to="/contact_us"
                  >
                    {t("NavBar.ContactUs")}
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {/* <LanguageSelector /> */}
    </div>
  );
};

export default Navbarmenu;
