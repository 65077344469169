import React, { useEffect } from "react";
import classes from "./Blogs.module.css";
import readMore from "../../imgs/Blogs/readmore.svg";
import shapeRight from "../../imgs/aboutUs/light-RIGHT.png";
import shapeLeft from "../../imgs/aboutUs/light-left.png";
import { NavLink } from "react-router-dom";
import blogeData from "./BlogData";
import { useTranslation } from "react-i18next";

export default function Blogs() {
  useEffect(() => {
    document.title = "Shore || Blogs"
  } , [])
  const { t } = useTranslation();

  return (
    <div className={classes.body}>
      <div className={classes.part1}>
        <hr className={classes.line}></hr>
        <h1>{t("Blogs.title")}</h1>
        <hr className={classes.line}></hr>
      </div>
      <div className={classes.blogs}>
        <div className={classes.rectangle}></div>
        {blogeData.map((item , index) => {
          return (
            <div className={classes.blog} key={index}>
              <img src={item.image} className={classes.image} alt="" />
              <h2 className={classes.title}>{t(item.title)}</h2>
              <p className={classes.Paraghraph}>{t(item.data)}</p>
              <NavLink to={`/blog/${item.id}`} className={classes.readMore}>
                <h2 className={classes.readMoreP}>{t("home.readMore")}</h2>
                <img src={readMore} className={classes.imageReadMore} alt="" />
              </NavLink>
              
            </div>
          );
        })}
      </div>
      <div className={classes.sharpes}>
        <img src={shapeRight} className={classes.shapeRight} alt="" />
        <img src={shapeRight} className={classes.shapeRight} alt="" />
        <img src={shapeRight} className={classes.shapeRight} alt="" />
        <img src={shapeRight} className={classes.shapeRight} alt="" />
        <img src={shapeLeft} className={classes.shapeRight} alt="" />
        <img src={shapeLeft} className={classes.shapeRight} alt="" />
        <img src={shapeLeft} className={classes.shapeRight} alt="" />
        <img src={shapeLeft} className={classes.shapeRight} alt="" />
      </div>
    </div>
  );
}
