import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function UseLocation() {
  let location = useLocation();
  const scrollTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    // console.log("s");
  };
  useEffect(() => {
    scrollTop();
  }, [location]);

  return <div></div>;
}
