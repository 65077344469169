import service from "../../imgs/servies/create-resume.jpg";
import service3 from "../../imgs/servies/tarika-7adisa-wazife.jpg";
import service2 from "../../imgs/servies/ekhtiyar-ekhtisas.jpg";
import servic4 from "../../imgs/servies/eshtiyezet-makalet.jpg";
import service5 from "../../imgs/servies/tatwir-wazife.jpg";
import service6 from "../../imgs/servies/ta8ir-masarak.jpg";
import service7 from "../../imgs/servies/tari2 ta3aml ma3 kayada.jpg";
import service8 from "../../imgs/servies/takhtit-mahani.jpg";
import service9 from "../../imgs/servies/tari2 estesmar 5ebrat motaka3idin.jpg";
const ServiesData = [
  {
    id: 1,
    title: "servies.title1",
    text: "servies.text1",
    image: service,
  },
  {
    id: 2,
    title: "servies.title2",
    text: "servies.text2",
    image: service3,
  },
  {
    id: 3,
    title: "servies.title3",
    text: "servies.text3",
    image: service2,
  },
  {
    id: 4,
    title: "servies.title4",
    text: "servies.text4",
    image: servic4,
  },
  {
    id: 5,
    title: "servies.title5",
    text: "servies.text5",
    image: service5,
  },
  {
    id: 6,
    title: "servies.title6",
    text: "servies.text6",
    image: service6,
  },
  {
    id: 7,
    title: "servies.title7",
    text: "servies.text7",
    image: service7,
  },
  {
    id: 8,
    title: "servies.title8",
    text: "servies.text8",
    image: service8,
  },
  {
    id: 9,
    title: "servies.title9",
    text: "servies.text9",
    image: service9,
  },
//   {
//     id: 10,
//     title: "servies.title10",
//     text: "servies.text10",
//     image: service10,
//   },
 
];

export default ServiesData;
