import React, { useEffect } from "react";
import classes from "./Home.module.css";
import SliderHome from "../../Components/SliderHome/SliderHome.js";
import AboutUsHome from "../../Components/AboutUsHome/AboutUsHome";
import Restarted from "../../Components/Restarted/Restarted";
import ServiesHome from "../../Components/Servies.js/ServiesHome";
import OurStory from "../../Components/OurStory/OurStory";
export default function Home() {
  useEffect(() => {
    document.title = "Shore || Home"
  } , [])
  return (
    <div className={classes.header}>
      <SliderHome />
      <AboutUsHome />
      <ServiesHome />
      <OurStory/>
      <Restarted />
    </div>
  );
}
