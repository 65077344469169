import React, { useEffect, useRef } from "react";
import classes from "./Contact.module.css";
import { BsTwitter, BsYoutube } from "react-icons/bs";
import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { AiOutlineInstagram, AiOutlineWhatsApp } from "react-icons/ai";
import { RiLinkedinFill } from "react-icons/ri";
import emailjs from "@emailjs/browser";
import shapeLeft from "../../imgs/home/LEFT.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function ContactUsnew() {
  useEffect(() => {
    document.title = "Shore || Contact us"
  } , [])
  const { t, i18n } = useTranslation();
  // funtion email js
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9us24no",
        "template_y2683v2",
        form.current,
        "topOxPGOOV24_1qaQ"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  // end email js
  return (
    <div className={classes.body}>
      {/* <div className={classes.part1}>
        <hr className={classes.line}></hr>
        <h1>{t("Contact.title")}</h1>
        <hr className={classes.line}></hr>
      </div> */}
      <div className={classes.part2}>
        <div className={classes.form}>
          <form className={classes.inputs} ref={form} onSubmit={sendEmail}>
            <input type="text" placeholder="الاسم الكامل" name="full_name" />
            <input type="email" placeholder="بريد الكتروني" name="user_email" />
            <textarea type="text" placeholder="رسالة" name="message" />
            <button
              className={classes.button}
              value="Send Message"
              type="submit"
            >
              {t("Contact.buttom")}
            </button>
          </form>
        </div>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3623.0933131630986!2d46.65013891535728!3d24.757989555453367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2slb!4v1679659629531!5m2!1sen!2slb"
          height="900"
          style={{ border: "0" }}
          className={classes.iframe}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      {/* <div className={classes.part3}> */}
      <div className={classes.SocialMidea}>
        <div className={classes.Contact}>
          <p className={classes.ContactP}> {t("Contact.title1")}</p>
          <p className={classes.ContactEmail}>{t("Contact.email")}</p>
        </div>
        <div className={classes.Based}>
          <p className={classes.ContactP}>{t("Contact.Basedin")}</p>
          <p className={classes.ContactEmail}>{t("Contact.Basedin.p")}</p>
        </div>
        {/* {lang === "en" ? ( */}

        <div className={classes.Based}>
          <p className={classes.ContactP}>{t("footer.ADDRESSPhone")}</p>
          <p className={classes.ContactEmail}>
            &#x202D;{t("footer.ADDRESSPhone2")}&#x202D;
          </p>
        </div>
      </div>

      {/* ):null} */}
      <div className={classes.footerPart1socialMedia}>
        <NavLink
          to="https://www.facebook.com/%D8%B4%D9%88%D8%B1-%D9%84%D9%84%D8%A5%D8%B1%D8%B4%D8%A7%D8%AF-%D8%A7%D9%84%D9%85%D9%87%D9%86%D9%8A-105794589118439/?mibextid=LQQJ4d"
          target={"_blank"}
        >
          <FaFacebookF size={50} className={classes.FaFacebookF} />
        </NavLink>
        <NavLink to="https://www.instagram.com/shorecareer/" target={"_blank"}>
          <AiOutlineInstagram size={50} />
        </NavLink>
        <NavLink to="https://twitter.com/shorecareer" target={"_blank"}>
          <BsTwitter size={50} />
        </NavLink>
        <NavLink to="https://www.tiktok.com/@shorecareer" target={"_blank"}>
          <FaTiktok size={50} />
        </NavLink>
        <NavLink
          to="https://www.linkedin.com/company/shorecareer/?original_referer="
          target={"_blank"}
        >
          <RiLinkedinFill size={50} />
        </NavLink>
        <NavLink
          to="https://api.whatsapp.com/send/?phone=966550288803&text&type=phone_number&app_absent=0"
          target={"_blank"}
        >
          <AiOutlineWhatsApp size={50} />
        </NavLink>
      </div>
      <div className={classes.footer}>
        <div className={classes.frame2}>
          <NavLink to="/">
            <img
              src={shapeLeft}
              className={classes.shapeRight}
              alt="BigCo Inc. logo"
            />
            <img
              src={shapeLeft}
              className={classes.shapeRight}
              alt="BigCo Inc. logo"
            />
          </NavLink>
        </div>
        <hr />
      </div>
    </div>

    // </div>
  );
}
