import React, { useState, useEffect } from "react";
import { FaShare } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

export default function Share() {
  const [openShareModel, setOpenShareModel] = useState(false);
  const [locationUrl, setLocationUrl] = useState(window.location.href);
  const [showAlert, setShowAlert] = useState(true);
  const [copiedText, setCopiedText] = useState("");

  const handleCopy = () => {
    navigator.clipboard.writeText(locationUrl);
    navigator.clipboard
      .readText()
      .then((text) => {
        setCopiedText(text);
      })
      .catch((err) => {
        setCopiedText(err);
      });
  };

  const handleClose = () => {
    setCopiedText(null);
    setOpenShareModel(false);
  };

  const handleShow = () => setOpenShareModel(true);

  useEffect(() => {
    setLocationUrl(window.location.href);
  }, [window.location.href]);

  return (
    <div>
      <div className="iconshare">
        <button className="Blog_like-btn" onClick={() => handleShow()}>
          <FaShare color="#7f9c9c" />{" "}
        </button>
        <p> مشاركة </p>
      </div>

      <Modal show={openShareModel} onHide={handleClose}
       centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>انسخ الرابط</Form.Label>
              <Form.Control
                type="text"
                placeholder={locationUrl}
                autoFocus
                disabled
              />
            </Form.Group>
          </Form>
          {copiedText == locationUrl ? (
            <Alert
              variant="success"
              // onClose={() => setShowAlert(false)}
              // dismissible
            >
              تم نسخ الرابط{" "}
            </Alert>
          ) : !copiedText ? (
            ""
          ) : (
            <Alert
              variant="danger"
              onClose={() => setShowAlert(false)}
              dismissible
            >
              Oh snap! You got an error!
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button style={{backgroundColor:"#7f9c9c",border:"none"}} onClick={handleCopy}>
            انسخ الرابط
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
