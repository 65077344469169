import React, { useEffect } from "react";
import classes from "./servies.module.css";
import serviesData from "./ServiesData";
import { useTranslation } from "react-i18next";
import service10 from "../../imgs/servies/tekyim-resume.jpg";

export default function Servies() {
  useEffect(() => {
    document.title = "Shore || Services"
  } , [])
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{t("home.Services.title")}</h1>

      <div className={classes.part1}>
        <div className={classes.part2} />
        {serviesData.map((item , index) => {
          return (
            <div className={classes.Services} key={index}>
              <div className={classes.imgsServices}>
                <img src={item.image} className={classes.image1} alt="" />
              </div>
              <div className={classes.ServicesContent}>
                <div className={classes.part3} />

                <h1 className={classes.Service}>{t(item.title)}</h1>
                <p className={classes.ServicesContentP}>{t(item.text)}</p>
              </div>
            </div>
          );
        })}
        <div className={classes.Services10}>
          <div className={classes.imgsServices10}>
            <img src={service10} className={classes.image10} alt="" />
          </div>
          <div className={classes.ServicesContent}>
            <div className={classes.part3} />

            <div className={classes.part10}>
              <h1 className={classes.Service}>{t("servies.title10")}</h1>
              <p className={classes.ServicesContentP}>{t("servies.text10")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
