import React from "react";
import classes from "./ServiesHome.module.css";
import service from "../../imgs/servies/create-resume.jpg";
import service3 from "../../imgs/servies/tarika-7adisa-wazife.jpg";
import service2 from "../../imgs/servies/ekhtiyar-ekhtisas.jpg";
import GroupCenter2 from "../../imgs/home/Group 58 (1).svg";
import GroupCenter from "../../imgs/home/Group 55Center.svg";
import GroupLeft from "../../imgs/home/Group 48Center2.svg";
import readMore from "../../imgs/home/readMore.png";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export default function ServiesHome() {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{t("home.Services.title")}</h1>

      <div className={classes.part1}>
        <div className={classes.part2} />

        <div className={classes.Services}>
          <div className={classes.imgsServices}>
            <img src={GroupLeft} className={classes.GroupLeft} alt="" />
            <img src={GroupCenter} className={classes.GroupCenter} alt="" />

            <img src={service} className={classes.image1} alt="" />
          </div>
          <div className={classes.ServicesContent}>
            <div className={classes.part3} />

            <h1 className={classes.Service}>{t("home.Services.Service1")}</h1>
            <p className={classes.ServicesContentP}>
              {t("home.Services.Service1.p")}
            </p>
          </div>
        </div>
        <div className={classes.Services}>
          <div className={classes.imgsServices}>
            <img src={service3} className={classes.image2} alt="" />
            <img src={GroupCenter2} className={classes.GroupCenter2} alt="" />
          </div>
          <div className={classes.ServicesContent}>
            <h1 className={classes.Service}>{t("home.Services.Service2")}</h1>
            <p className={classes.ServicesContentP}>
              {t("home.Services.Service2.p")}
            </p>
          </div>
        </div>
        <div className={classes.Services}>
          <div className={classes.imgsServices}>
            <img src={service2} className={classes.image3} alt="" />
            <img src={GroupCenter2} className={classes.GroupCenter3} alt="" />
          </div>

          <div className={classes.ServicesContent}>
            <h1 className={classes.Service}>{t("home.Services.Service3")}</h1>
            <p className={classes.ServicesContentP}>
              {t("home.Services.Service3.p")}
            </p>
          </div>
        </div>
      </div>
      <NavLink to={`/servies`} className={classes.readMore}>
            <div className={classes.grpReadMore}>
              <p className={classes.part2pRead}> {t("home.readMoreServies")}</p>
              <img
                src={readMore}
                className={classes.imgReadMore}
                alt="BigCo Inc. logo"
              />
            </div>
          </NavLink>
    </div>
  );
}
