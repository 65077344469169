import React from "react";
import classes from "./OurStory.module.css";
import shapeLeft from "../../imgs/home/LEFT (1).png";
import shapeRight from "../../imgs/home/RIGHT (1).png";
import readMore from "../../imgs/home/readMore.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function OurStory() {
  const { t } = useTranslation();

  return (
    <div className={classes.Container}>
      <div className={classes.part1}>
        <hr className={classes.line}></hr>
        <h1>{t("ourStory.title")}</h1>
        <hr className={classes.line}></hr>
      </div>
      <div className={classes.part2}>
        {/* <div className={classes.arrow}>
          <img
            src={shapeRight}
            className={classes.shapeLeft}
            alt="BigCo Inc. logo"
          />
        </div> */}
        <div className={classes.part2paraghraph}>
          <p className={classes.partParaghraph}>{t("ourStory.p1")}</p>
          <p className={classes.partParaghraph}>{t("ourStory.p2")}</p>
          <p className={classes.partParaghraph}>{t("ourStory.p3")}</p>
        </div>

        {/* <div className={classes.arrow}>
          <img
            src={shapeLeft}
            className={classes.shapeLeft}
            alt="BigCo Inc. logo"
          />
        </div> */}
      </div>
    </div>
  );
}
