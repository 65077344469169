import React from "react";
import classes from "./Restarted.module.css";
import Screenshot from "../../imgs/home/Life-Coaching Background.jpg";
import { useTranslation } from "react-i18next";

export default function Restarted() {
  const { t } = useTranslation();

  return (
    <div className={classes.header}>
      <div className={classes.part1}>
        <hr className={classes.line}></hr>
        <h1>{t("home.Restarted.title")}</h1>
        <hr className={classes.line}></hr>
      </div>
      <img src={Screenshot} className={classes.image} alt="" />
      <div className={classes.part2}>
        <p className={classes.part2p}>{t("home.Restarted.p")}</p>
      </div>
    </div>
  );
}
