import React, { useState } from "react";
import classes from "./SliderHome.module.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import logo2 from "../../imgs/logo512.png";
import sliderImg1 from "../../imgs/Slider/slider 1.jpg";
import sliderImg2 from "../../imgs/Slider/slider 2.jpg";
import sliderImg3 from "../../imgs/Slider/slider 3.jpg";
import sliderImg4 from "../../imgs/Slider/slider 4.jpg";
import sliderImg5 from "../../imgs/Slider/slider 5.jpg";


export default function SiderHome() {
  const GalleryImagesNew = [sliderImg5, sliderImg1, sliderImg2, sliderImg3, sliderImg4];
  const [slideNumber, setSlideNumber] = useState(0);

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(GalleryImagesNew.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  // Next Image
  const nextSlide = () => {
    slideNumber + 1 === GalleryImagesNew.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };
  return (
    <div>
      <div className={classes.header}>
        <div className={classes.FaChevronLeft}>
          <IoIosArrowBack color="white" onClick={prevSlide} size={50} />
        </div>

        <div className={classes.Slider}>
          <img
            src={GalleryImagesNew[slideNumber]}
            className={classes.image}
            alt=""
          />
        </div>

        <div className={classes.FaChevronRight}>
          <IoIosArrowForward color="white" onClick={nextSlide} size={50} />
        </div>
        <div className={classes.sliderPointers}>
          {GalleryImagesNew.map((img, index) => (
            <div
              key={index}
              className={
                index === slideNumber
                  ? classes.sliderPointer + " " + classes.active
                  : classes.sliderPointer
              }
              onClick={() => {
                setSlideNumber(index);
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}
